<template>
  <div class="container">
    <c-flex mt="20px" mb="8" align="center" justify="space-between">
      <c-text fontWeight="700" fontSize="xl">Notifications</c-text>
    </c-flex>

    <div>
      <div v-if="notifications.length === 0">
        <c-text fontSize="sm">No notifications</c-text>
      </div>
      <div v-else>
        <div v-for="notification in notifications" :key="notification.id">
          <div class="notification" @click="goToNotification(notification)">
            <div class="user_name">
              <span>{{ getFirstLetterAndSecond(notification.message) }}</span>
            </div>
            <div class="_message">
              <div class="notification-dot"></div>
              <div>
                <c-text fontSize="sm">{{
                  capitalizeFirstLetter(notification.message)
                }}</c-text>
                <c-text fontSize="xs">{{
                  notification.createdAt | moment('from')
                }}</c-text>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'NotificationsPage',
  computed: {
    moment() {
      return moment;
    },
    ...mapState('company', {
      notifications: (state) => state.notifications,
    }),
  },
  methods: {
    getFirstLetterAndSecond(string) {
      return string.charAt(0).toUpperCase() + string.charAt(1).toUpperCase();
    },
    goToNotification(notification) {
      console.log(notification);
      if (notification.data) {
        let type = notification.data.type;
        type = type.replace(/_/g, '-');
        const page = this.pages.find((_page) =>
          _page.components.includes(type)
        );
        if (page) {
          this.$router.push({
            name: page.name,
            query: {
              to_component: type,
            },
          });
          this.closeDrawer();
        }
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style scoped>
.container {
  background-color: #f4f8fe;
}
.notification {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  padding: 5px;
  margin-bottom: 5px;
}
.notification-dot {
  @apply h-3 w-3 rounded-full border cursor-pointer;
  background-color: green;
  border-color: green;
  margin-right: 10px;
  margin-top: 4px;
}

._message {
  display: flex;
}

/* hover bacground color */
.notification:hover {
  background-color: #e9edf4;
  border-color: #e2e8f0;
}

.user_name {
  float: right;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  height: 45px;
  width: 45px;
  background-color: #e2e8f0;
  border-radius: 6px;
}
</style>
